<template>
  <div>
    <form
      class="formgrid grid flex align-items-center"
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
      autocomplete="off"
    >
      <template v-if="addAmendment">
        <div class="field col-12 sm:col-6 mt-2">
          <label for="name">Amendment Label</label>
          <div class="p-inputgroup">
            <InputText
              placeholder="Amendment Label"
              v-model="amendment.title"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.amendment.title.required"
          >
            Label is required
          </div>
        </div>
        <div class="field col-12 sm:col-6 mt-2">
          <label for="name">Estimated Time</label>
          <div class="p-inputgroup">
            <InputMask
              placeholder="Estimated Time"
              v-model="amendment.estimated_hours"
              mask="99:99"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.amendment.estimated_hours.required"
          >
            Time is required
          </div>
        </div>
        <div class="field col-12">
          <Editor
            ref="editor"
            id="Description"
            placeholder="Description"
            :autoResize="true"
            v-model="amendment.brief"
            editorStyle="height:150px"
          >
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button><button class="ql-strike"></button>
                <button
                  class="ql-list"
                  value="ordered"
                ></button>
                <button
                  class="ql-list"
                  value="bullet"
                ></button>
                <button class="ql-align"></button>
                <button class="ql-link"></button>
              </span> </template>
          </Editor>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.amendment.brief.required"
          >
            Brief is required
          </div>
        </div>

        <div class="col field flex align-items-flex-start mt-3">Documents</div>

        <div class="field col-12 cursor-pointer">
          <file-pond
            name="profile"
            ref="pond"
            class="font-light"
            label-idle="Drop documents or select files"
            v-bind:allow-multiple="true"
            v-bind:files="myFiles"
            v-on:init="handleFilePondInit"
            v-on:processfile="handleProcessFile"
          />
        </div>
        <div class="field col-12 cursor-pointer">
          <div class="flex justify-content-between pt-3 pb-3">
            <Button type="submit"> ADD AMENDMENT </Button>
            <Button
              class="p-button-text p-button-secondary"
              @click="$emit('cancel')"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </template>
      <div class="field col-12 mt-3">
        <Panel
          :toggleable="true"
          :collapsed="!isExpanded(a)"
          v-for="(a, index) in amendments"
          :key="a.uid"
          :class="
            index !== amendments.length - 1
              ? 'no-icon-panel mb-3'
              : 'no-icon-panel '
          "
        >
          <template #header>
            <div
              class="flex w-full cursor-pointer p-3"
              @click="handleHeaderClick(a.uid)"
            >
              <div
                class="col font-normal"
                style="display: flex; align-items: center"
              >
                <i
                  class="pi pi-chevron-right mr-2"
                  :class="{ 'rotate-90': isExpanded(a) }"
                  style="flex-shrink: 0; vertical-align: top"
                ></i>

                <span style="flex-grow: 1; padding-left: 5px">
                  {{ a.title }}
                </span>
              </div>
              <div class="text-right font-normal pr-4">
                <span class="font-light">Additional Time:</span>
                {{ a.estimated_hours ?? "0:00" }}
              </div>
              <div class="text-right font-normal">
                <span class="font-light">Added:</span>
                <template v-if="a.created_date">
                  {{ a.created_date.nice }}
                </template>
              </div>
            </div>
          </template>

          <div v-if="
              (!a.brief || (a.brief && a.brief.length < 1)) &&
              !a.title &&
              !a.uid
            ">
            Nothing added yet...
          </div>
          <TaskAmendment
            v-else
            :task="task"
            :amendment="a"
          ></TaskAmendment>
        </Panel>
      </div>
    </form>
  </div>
</template>
<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import TaskAmendment from "@/application/Tasks/components/TaskAmendment";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { fetchAmendments, state } from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
// Create component
var FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  name: "TaskAmendments",
  props: {
    taskProp: {
      type: Object,
    },
    addAmendment: {
      type: Boolean,
    },
  },
  components: {
    FilePond,
    TaskAmendment,
  },
  data() {
    return {
      submitted: false,
      amendment: {
        title: null,
        brief: "",
      },
      expanded: [],
      task: null,
      currentPond: null,
      hover: false,
      amendmentTime: "",
      showDrop: [],
      amendmentDateStart: "",
      amendmentDateEnd: "",
      amendmentBrief: "",
      task_files: [],
      title: "",
      myFiles: [],
      active: 0,
    };
  },
  validations: {
    amendment: {
      brief: {
        required,
      },
      title: {
        required,
      },
      estimated_hours: {
        required,
      },
    },
  },
  created() {
    if (this.taskProp) {
      this.task = this.taskProp;
    }
  },
  methods: {
    isExpanded(a) {
      return this.expanded.includes(a.uid);
    },
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        try {
          this.$store.dispatch("setSliderLoader", true);
          var form = new FormData();

          if (this.task_files && this.task_files.length > 0) {
            form.append("task_files", JSON.stringify(this.task_files));
          }

          form.append("title", this.amendment.title);
          form.append("brief", this.amendment.brief);
          form.append("estimated_hours", this.amendment.estimated_hours);

          form.append("task", this.task.uid);

          await this.$axios.post(
            process.env.VUE_APP_ROOT_API + "/v1/tasks/amendment",

            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          );
          this.$emit("cancel");
          await fetchAmendments(this.task.uid);
          this.$store.dispatch("setSliderLoader", false);
        } catch (error) {
          this.$store.dispatch("setSliderLoader", false);
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        }
      }
    },
    handleHeaderClick(uid) {
      if (this.expanded.includes(uid)) {
        this.expanded = this.expanded.filter((c) => c !== uid);
      } else {
        this.expanded.push(uid);
      }
    },
    handleProcessFile: function (error, file) {
      const response = JSON.parse(file.serverId);
      this.task_files.push(response.data);
    },
    handleFilePondInit() {
      this.$refs["pond"].getFiles();
    },
  },
  watch: {
    taskProp(n) {
      this.task = n;
    },
    amendments(n, o) {
      if (n?.length > o?.length) {
        const newUid = n.find((a) => !o.map((b) => b.uid).includes(a.uid));
        this.expanded.push(newUid.uid);
      }
    },
  },
  computed: {
    amendments() {
      return state.amendments;
    },
  },
  async mounted() {
    setOptions({
      server: {
        url: process.env.VUE_APP_ROOT_API + "/",
        timeout: 7000,
        process: {
          url: "./v1/tasks/upload",
          method: "POST",
          headers: {
            Authorization: "Bearer " + this.$store.getters.token,
          },
        },
      },
    });
  },
};
</script>
<style>
.no-icon-panel .p-panel-header .p-panel-icons {
  display: none !important;
}

.p-panel-header {
  padding: 0px !important;
}

.pi-chevron-right {
  transition: transform 0.25s ease-in-out;
}

.rotate-90 {
  transform: rotate(90deg);
}
</style>